import * as $ from "jquery";

export const initFilterMenu = (): void => {
    if($(".car-list-filter").length) {
        $(".filter-header").on("click", function() {
            $(this).parent()
                .find(".filter--group-content")
                .slideToggle("fast");
            $(this).parent().toggleClass("open");
        });

        $(".js--show-mobile-filters, .filter-close-button").on("click", function(e) {
            e.preventDefault();
            e.stopPropagation();
            $("body").toggleClass("cart-list-filter--active");
        });
    }
}