import * as $ from "jquery";
import "bootstrap";
import "slick-carousel";
import { initFilterMenu } from "./modules/CarFilter";
import * as MobileDetect from "mobile-detect";
// import "./modules/mobile-detect";

$(() => {
  initFilterMenu();

  $(".car-slider-image").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    infinite: false,
    asNavFor: ".car-slider-nav",
  });
  $(".car-slider-nav").slick({
    slidesToShow: 8,
    asNavFor: ".car-slider-image",
    infinite: false,
    arrows: false,
  });
  $(".car-slider-nav .slick-slide").on("click", function () {
    $(".car-slider-image").slick(
      "slickGoTo",
      parseInt($(this).data("slickIndex"))
    );
  });

  $(".car--list-slider_slider").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: $(".car--list-slider .slider-navigator .prev"),
    nextArrow: $(".car--list-slider .slider-navigator .next"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".partners--list-slider_slider").slick({
    slidesToShow: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: $(".partners--list-slider .slider-navigator .prev"),
    nextArrow: $(".partners--list-slider .slider-navigator .next"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $(".navbar-toggler").on("click", function () {
    $(this).toggleClass("is-active");
    $("body").toggleClass("menu-active");
  });

  $(".languages-switch li a.lang-switcher, .language-select a.lang-switcher").each(function (index) {
    let regex = /\/(\w{2})\/|^\/(\w{2})$/;
    var lang = String($(this).attr("href"));
    var p = window.location.pathname + window.location.search;
    if (regex.test(window.location.pathname + window.location.search)) {
      $(this).attr("href", p.replace(regex, lang + "/"));
    } else {
      $(this).attr("href", lang + p);
    }
  });

  $(".btn-social").on("click", function (e) {
    e.preventDefault();
    window.open(
      $(this).attr("href"),
      "fbShareWindow",
      "height=450, width=650, top=" +
        (window.innerHeight / 2 - 275) +
        ", left=" +
        (window.innerWidth / 2 - 325) +
        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
    return false;
  });



  $(".btn-copy-url").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();
    let textArea : HTMLInputElement;
    function createTextArea(val : string){
      textArea = document.createElement('input');
      textArea.value = val;
      let elem = document.getElementById('temp');
      console.log(elem);
      if(elem){
          elem.appendChild(textArea);
      }
    }

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function copyToClipboard(){
      let res = document.execCommand('copy');
      let elem = document.getElementById('temp');
      if(elem){
          elem.removeChild(textArea);
      }
      return res;
    }

    function selectText(){
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        if(selection){
          selection.removeAllRanges();
          selection.addRange(range);
        }
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }
    let md = new MobileDetect(window.navigator.userAgent);
    if(isOS() || md.phone() == 'UnknownPhone'){
      createTextArea(window.location.href);
      selectText();
      if(copyToClipboard()){
        alert("Nuoroda išsaugota iškarpinėje");
      }else{
        alert("Klaida kopijuojant nuorodą");
      }
    }else{
      navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            alert("Nuoroda išsaugota iškarpinėje");
          })
          .catch((error) => {
            $(this).prop("disabled", true);
            alert("Klaida kopijuojant nuorodą");
          });
    }
    return false;
  });

  // if(navigator.userAgent.match(/MediaPad|MediaPad 7 Youth|IDEOS S7|S7-201c|S7-202u|S7-101|S7-103|S7-104|S7-105|S7-106|S7-201|S7-Slim|M2-A01L|BAH-L09|BAH-W09|AGS-L09|CMR-AL19/i)){
  //   alert('test');
  // }else{
  //   alert('test1');
  // }

  if (window.innerWidth > 768) {
    $("#search_form input, #search_form select").change(function () {
      $("#search_form").submit();
    });
  }

  $(".brand-selector").on("click", function (e) {
    e.preventDefault();
    if ($("#brand").val() !== $(this).data("brand")) {
      $("#model").val("");
    }
    $("#brand").val($(this).data("brand")).trigger("change");
  });

  const $selects = $("#sort_main, #sort_by").change(function () {
    $selects.not(this).val($(this).val() as string);
    $("#search_form").submit();
  });

  const $show_select = $("#show, #per_page").change(function () {
    $show_select.not(this).val($(this).val() as string);
    $("#search_form").submit();
  });

  $('input[name ="subtype_id"]').on("change", function(){
      if($(this).data('href')){
          window.location.href = $(this).data('href');
          return;
      }
      $("form#seach_form input:not([name='subtype_id'])").val("");
  });
  $(".toggle-text-query").on("click", function(event){
    event.preventDefault();
    $("#text-query-mobile").animate({width: 'toggle'}, 350);
  });


  $("#search_mobile_btn").on("click", function(event){
    if($("#text-query-mobile").val() === ""){
      event.preventDefault();
      $("#text-query-mobile").animate({width: 'toggle', paddingLeft: 'toggle', paddingRight: 'toggle'}, 450);
    }else{
      $("#search_mobile").submit();
    }
  });
});
